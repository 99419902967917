/* eslint-disable flowtype/no-types-missing-file-annotation */
/* eslint-disable react/prop-types */

import React from 'react'
import FlexView from 'react-flexview/lib'
import './buttonstyles.scss'
var classNames = require('classnames')

type NewFillButtonProps = {
    onClick: Function,
    disabled: Boolean,
}

export default class NewFillButton extends React.Component {
    props: NewFillButtonProps

    onClick = () => {
        !this.props.disabled && this.props.onClick && this.props.onClick()
    }

    render() {
        return (
            <FlexView
                hAlignContent={'center'}
                vAlignContent={'center'}
                onClick={this.onClick}
                className={classNames(
                    { 'button-disabled': this.props.disabled },
                    {
                        'button-darkblue-newfill':
                            !this.props.disabled &&
                            !this.props.green &&
                            this.props.darkblue,
                    },
                    {
                        button:
                            !this.props.disabled &&
                            !this.props.green &&
                            !this.props.darkblue,
                    },
                    { flat: this.props.flat },
                    {
                        'button-green-newfill':
                            !this.props.disabled &&
                            !this.props.darkblue &&
                            this.props.green,
                    }
                )}
            >
                <div className="btn-text">{this.props.children}</div>
            </FlexView>
        )
    }
}
