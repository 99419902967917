import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { SessionService } from '../../../services/SessionService'

export const FlowBrokerProtectedRoute = ({ children, ...rest }) => {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                (SessionService.isBroker() &&
                    SessionService.getStoredBrokerOnBehalfToken()) ||
                !(SessionService.isLoggedIn() && SessionService.isBroker()) ||
                !SessionService.isLoggedIn() ? (
                    children
                ) : (
                    //TODO: maybe a different page explaining the error? not broker
                    <Redirect
                        to={{
                            pathname: '/broker',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    )
}
