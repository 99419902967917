/* eslint-disable flowtype/no-types-missing-file-annotation */

export class ExtensionLiabilityOfferModel {
    avgFlightScoreMultiplier: number
    commercial: boolean
    creditUtilization: Number
    finalCost: Number
    finalPrice: Number
    liabilityLimit: Number
    offerId: string
    price: Number
    priceWithTaxes: Number
    taxes: Number
    taxesMultiplier: Number
    totalCoverageHoursMultiplier: Number
    totalDiscount: Number
    personalInjuryLimit: Number
    personalInjuryLimitDiscount: Number
    personalInjuryLimitPremium: Number
    medicalExpenseLimit: Number
    medicalExpenseLimitDiscount: Number
    medicalExpenseLimitPremium: Number
    territorialLimitsPremium: Number
}
