import React from 'react'
import FlexView from 'react-flexview/lib'
import './ImagePreview.scss'
import CheckIcon from '@material-ui/icons/Check'
import ReplayIcon from '@material-ui/icons/Replay'
import { IconButton } from '@material-ui/core'

export const ImagePreview = ({ dataUri, onAccept, onCancel }) => {
    var isLandscape = window.matchMedia('(orientation: landscape)').matches
    return (
        <FlexView column className="priview-background">
            <FlexView className="priview-background2">
                <FlexView column className="preview-content">
                    <FlexView
                        flex={1}
                        hAlignContent={'center'}
                        vAlignContent={'center'}
                        className="preview-title-top"
                    >
                        <img
                            className="logo-fit"
                            src={
                                process.env.PUBLIC_URL +
                                '/assets/voom_white.png'
                            }
                        />
                    </FlexView>
                    <FlexView flex={3} hAlignContent={'center'}>
                        <img className="preview-image" src={dataUri} />
                    </FlexView>
                    <FlexView
                        flex={1}
                        hAlignContent={'center'}
                        vAlignContent={'center'}
                        className="preview-title-bottom space-evenly"
                    >
                        <IconButton
                            onClick={onCancel}
                            className="img-retry-btn"
                        >
                            <ReplayIcon className="white large-icon clickable" />
                        </IconButton>
                        <IconButton
                            onClick={onAccept}
                            className="img-ok-btn margin-left-basic"
                        >
                            <CheckIcon className="white large-icon clickable" />
                        </IconButton>
                    </FlexView>
                </FlexView>
            </FlexView>
        </FlexView>
    )
}
