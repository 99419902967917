import { ActiveInsuranceModel } from './insurance-models/ActiveInsuranceModel'
import { LiabilityExtension } from './insurance-models/LiabilityExtension'
import { PhysicalDamageExtension } from './insurance-models/PhysicalDamageExtension'
import { ExtensionLiabilityOfferModel } from './insurance-models/ExtensionLiabilityOfferModel'
import { InsuranceOfferModel } from './insurance-models/InsuranceOfferModel'
import { InsuranceOffersSummary } from './insurance-models/InsuranceOffersSummary'
import { InsuranceOptionModel } from './insurance-models/InsuranceOptionModel'
import { AdditionalInsuredEndorsementExtension } from './insurance-models/AdditionalInsuredEndorsementExtension'
import UserProfileModel from './user-models/UserProfileModel'
import { Operator } from './insurance-models/Operator'

export class Transformer {
    static toCamel(s) {
        return s.replace(/([-_][a-z])/gi, ($1) => {
            return $1.toUpperCase().replace('-', '').replace('_', '')
        })
    }

    static parseISOLocal(s) {
        if (!s) return undefined
        var b = s.split('-')
        return new Date(b[0], b[1] - 1, b[2])
    }

    static initializeFromData(obj, data) {
        Object.keys(data).forEach((key) => {
            let clsKey = this.toCamel(key)
            if (key.includes('utc')) {
                if (data[key].endsWith('Z')) obj[clsKey] = new Date(data[key])
                else obj[clsKey] = new Date(data[key] + 'Z')
            } else if (key == 'date_of_birth') {
                obj[clsKey] = this.parseISOLocal(data[key])
            } else if (key.includes('date')) {
                obj[clsKey] = new Date(data[key])
            } else {
                obj[clsKey] = data[key]
            }
        })
        return obj
    }

    static initializeFromDataDeep(obj, data) {
        Object.keys(data).forEach((key) => {
            let clsKey = this.toCamel(key)
            if (key.includes('utc')) {
                if (data[key].endsWith('Z')) obj[clsKey] = new Date(data[key])
                else obj[clsKey] = new Date(data[key] + 'Z')
            } else if (key.includes('date')) {
                obj[clsKey] = new Date(data[key])
            } else if (
                data[key].constructor == Object &&
                !Array.isArray(data[key])
            )
                obj[clsKey] = this.initializeFromDataDeep({}, data[key])
            else if (Array.isArray(data[key])) {
                obj[clsKey] = data[key].map((item) =>
                    Transformer.initializeFromDataDeep({}, item)
                )
            } else {
                obj[clsKey] = data[key]
            }
        })
        return obj
    }

    static parseUserModel(data) {
        let user: UserProfileModel = Transformer.initializeFromDataDeep(
            new UserProfileModel(),
            data
        )
        let split = UserProfileModel.fullNameSplitter(data.full_name)
        user.firstName = split[0]
        user.lastName = split[1]
        return user
    }

    static parseOffers(arrData) {
        let arrOpt = arrData.map((optionData) =>
            Transformer.initializeFromData(
                new InsuranceOptionModel(),
                optionData
            )
        )
        let offersLiability = {}
        let limitationOffers = []

        arrOpt.forEach((option) => {
            let insur = offersLiability[option.liabilityLimit]
            if (insur == undefined) {
                insur = new InsuranceOfferModel()
                insur.liabilityLimit = option.liabilityLimit
                insur.options = []
                limitationOffers.push(insur)
                offersLiability[option.liabilityLimit] = insur
            }
            insur.options.push(option)
        })

        limitationOffers.forEach((insur) => {
            insur.options.sort((a, b) => {
                return a.duration - b.duration
            })
        })

        limitationOffers.sort((a, b) => {
            return a.liabilityLimit - b.liabilityLimit
        })
        arrOpt.sort((a, b) => {
            return a.liabilityLimit - b.liabilityLimit
        })
        return new InsuranceOffersSummary(limitationOffers, arrOpt, false)
    }

    static parseActiveInsuranceModelArray(array) {
        let activeArray = []
        for (let active of array) {
            let activeInsurance = Transformer.parseActiveInsuranceModel(active)
            activeArray.push(activeInsurance)
        }
        return activeArray
    }

    static parseActiveInsuranceModel(active) {
        let activeInsurance: ActiveInsuranceModel =
            Transformer.initializeFromDataDeep(
                new ActiveInsuranceModel(),
                active
            )
        // activeInsurance.finalPrice = active["final_price"] ? active["final_price"] : 0;
        activeInsurance.dronePremium = active['total_drone_premium']
            ? active['total_drone_premium']
            : 0
        activeInsurance.equipmentPremium = active['total_equipment_premium']
            ? active['total_equipment_premium']
            : 0
        // activeInsurance.liabilityLimitPremium = active["liability_limit_premium"] ? active["liability_limit_premium"] : 0;
        // activeInsurance.liabilityLimitDiscount = active["liability_limit_discount"] ? active["liability_limit_discount"] : 0;
        // activeInsurance.policyUrl = active["policy_url"] ? active["policy_url"] : "";
        // activeInsurance.certificateUrl = active["certificate_url"];
        
        activeInsurance.insuranceType = active['insurance_period_description']
        // activeInsurance.insuranceId = active["insurance_id"];
        // activeInsurance.locationDescription = active["location_description"];
        // activeInsurance.policyNumber = active["policy_number"];
        // activeInsurance.summary = active["summary"];
        activeInsurance.flightArea = active['covered_airspace']
        activeInsurance.timeLeftSeconds = active['remaining_seconds']
        // activeInsurance.autoRenew = active["auto_renew"];
        // activeInsurance.renewable = active["renewable"];
        // activeInsurance.liabilityLimit = active["liability_limit"];
        activeInsurance.insuranceStart = new Date(
            `${active['valid_from_utc']}${
                active['valid_from_utc'].endsWith('Z') ? '' : 'Z'
            }`
        )
        activeInsurance.insuredDrones = active['insured_drone_list']
        activeInsurance.insuredEquipment = active['insured_equipment_list']
        activeInsurance.insuranceEnd = new Date(
            `${active['valid_until_utc']}${
                active['valid_until_utc'].endsWith('Z') ? '' : 'Z'
            }`
        )
        activeInsurance.durationInSeconds =
            (activeInsurance._insuranceEnd.getTime() -
                activeInsurance._insuranceStart.getTime()) /
            1000
        activeInsurance.additionalInsuredList = active['additional_insureds']
        // activeInsurance.additionalInsuredExtensions = active['additional_insured_extensions'] ? active['additional_insured_extensions'].map(item => Transformer.initializeFromData(new AdditionalInsuredEndorsementExtension(), item)) : []
        // activeInsurance.liabilityLimitExtensions = active['liability_limit_extensions'] ? active['liability_limit_extensions'].map(item => Transformer.initializeFromData(new LiabilityExtension(), item)) : []
        // activeInsurance.physicalDamageExtensions = active['physical_damage_extensions'] ? active['physical_damage_extensions'].map(item => Transformer.initializeFromData(new PhysicalDamageExtension(), item)) : []
        // activeInsurance.indoorCoverageIncluded = active['indoor_coverage_included'] ? active['indoor_coverage_included'] : false
        // activeInsurance.isSuspended = Boolean(active['is_suspended'])
        activeInsurance.isSuspendable = Boolean(active['suspendable'])
        activeInsurance.suspensionStartTime = active['suspension_start_time']
            ? new Date(active['suspension_start_time'] + 'Z')
            : undefined
        activeInsurance.suspensionEndTime = active['suspension_end_time']
            ? new Date(active['suspension_end_time'] + 'Z')
            : undefined
        // activeInsurance.dateAdded = active['date_added'] ? new Date( active['date_added'] + "Z") : undefined
        // activeInsurance.operators = active['operators'] ? active['operators'].map(op => Transformer.initializeFromData(new Operator(), op)) : []
        // activeInsurance.subscription = active['subscription'] ? Transformer.initializeFromDataDeep({}, active['subscription']) : undefined
        return activeInsurance
    }

    static parseLiabilityLimitExtentionOffers(offers: any[]) {
        return offers.map((optionData) => {
            optionData['offer_id'] = optionData['id']
            return Transformer.initializeFromData(
                new ExtensionLiabilityOfferModel(),
                optionData
            )
        })
    }
}
