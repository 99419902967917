import ApiService, { HTTPMethod } from './api.core'
import { Transformer } from '../models/Transformer'
import { PhysicalDamageExtensionOffer } from '../models/insurance-models/PhysicalDamageExtensionOffer'
import { getAiWithoutId } from '../services/utils'

class PolicyManagementApi {
    async getAllInsurances() {
        let res = await ApiService.fetch('insurances/me', HTTPMethod.GET)
        if (res.ok) {
            res.parsedData = Transformer.parseActiveInsuranceModelArray(
                res.data
            )
        }
        return res
    }

    async getPhysicalDamageOffer(insuranceId, drones, equipment) {
        let res = await ApiService.fetch(
            `insurances/${insuranceId}/extension_offers/physical_damage`,
            HTTPMethod.POST,
            {
                drones: drones,
                equipment: equipment,
            }
        )
        if (res.ok) {
            res.parsedData = Transformer.initializeFromData(
                new PhysicalDamageExtensionOffer(),
                res.data['physical_damage_extension_offer']
            )
        }
        return res
    }

    async applyHullExtension(insuranceId, physicalDamageExtensionOfferId) {
        let res = await ApiService.fetch(
            `insurances/${insuranceId}/extensions/physical_damage`,
            HTTPMethod.POST,
            {
                physical_damage_extension_offer_id:
                    physicalDamageExtensionOfferId,
            }
        )
        if (res.ok) {
            res.parsedData = Transformer.parseActiveInsuranceModel(res.data)
        }
        return res
    }

    async modifyExistingHull(insuranceId, drones, equipment) {
        let res = await ApiService.fetch(
            `insurances/${insuranceId}`,
            HTTPMethod.PUT,
            {
                insured_drone_list: drones,
                insured_equipment_list: equipment,
            }
        )
        res.parsedData = Transformer.parseActiveInsuranceModel(res.data)
        return res
    }

    async addAdditionalInsured(insuranceId, additionalInsured) {
        let res = await ApiService.fetch(
            `insurances/${insuranceId}`,
            HTTPMethod.PUT,
            {
                additional_insureds: additionalInsured
                    .map((q) => getAiWithoutId(q))
                    .map((q) => {
                        return {
                            ...q,
                            email: q.email ? q.email : undefined,
                            newId: undefined,
                        }
                    }),
            }
        )
        res.parsedData = {
            insurance: Transformer.parseActiveInsuranceModel(res.data),
        }
        return res
    }

    async enableAutoRenew(insuranceId) {
        return ApiService.fetch(`insurances/${insuranceId}/subscription/enable`)
    }

    async disableAutoRenew(insuranceId) {
        return ApiService.fetch(`insurances/${insuranceId}/subscription/cancel`)
    }

    async getLiabilityExtensionOffers(insuranceId) {
        let res = await ApiService.fetch(
            `insurances/${insuranceId}/extension_offers/liability_limit`,
            HTTPMethod.POST,
            {}
        )
        if (res.ok) {
            res.parsedData = Transformer.parseLiabilityLimitExtentionOffers(
                res.data.liability_limit_extension_offers
            )
        }
        return res
    }

    async applyLiabilityExtension(insuranceId, extensionOfferId) {
        let res = await ApiService.fetch(
            `insurances/${insuranceId}/extensions/liability_limit`,
            HTTPMethod.POST,
            {
                insurance_id: insuranceId,
                liability_limit_extension_offer_id: extensionOfferId,
            }
        )
        res.parsedData = res.data
            ? Transformer.parseActiveInsuranceModel(res.data)
            : null
        return res
    }

    async suspendPolicy(insuranceId, endDate) {
        let body = !!endDate ? { suspension_end_time: endDate } : {}
        return await ApiService.fetch(
            `insurances/${insuranceId}/suspend`,
            HTTPMethod.PUT,
            body
        )
    }

    async unsuspendPolicy(insuranceId) {
        return await ApiService.fetch(
            `insurances/${insuranceId}/unsuspend`,
            HTTPMethod.PUT
        )
    }

    async FullCancelPolicy(insuranceId, freeOfCharge) {
        let res = await ApiService.fetch(
            `insurances/${insuranceId}/fullCancel`,
            HTTPMethod.POST, freeOfCharge
        )
        return res
    }

    async cancelPolicyProRata(insuranceId, needMinimumEarned) {
        let res = await ApiService.fetch(
            `insurances/${insuranceId}/cancelProRata`,
            HTTPMethod.POST, needMinimumEarned
        )
        return res
    }

    async calcFullCancellationAmount(insuranceId, freeOfCharge) {
        return await ApiService.fetch(
            `insurances/${insuranceId}/getFullCancelAmount`,
            HTTPMethod.POST, freeOfCharge
        )
    }

    async calcCancellationProRataAmount(insuranceId, needMinimumEarned) {
        return await ApiService.fetch(
            `insurances/${insuranceId}/proRataCancelRefundAmount`,
            HTTPMethod.POST, needMinimumEarned
        )
    }
}

export default new PolicyManagementApi()
