import ApiService, { HTTPMethod } from './api.core'
import { Transformer } from '../models/Transformer'
import UserProfileModel from '../models/user-models/UserProfileModel'
import State from '../models/user-models/State'
import dayjs from 'dayjs'
import Resizer from '../utilities/Resizer'
import {
    convertToYesOrNo,
    dataImageToFile,
    removeDuplicateAdditionalInsured,
} from '../services/utils'
import CreditCardModel from '../models/user-models/CreditCardModal'
import { AdditionalInsuredModel } from '../models/user-models/AdditionalInsuredModel'
import { FirebaseService, FirebaseConfigKey } from '../services/FirebaseService'
import DataLayerService from '../services/DataLayerService'
import { buildQueryParamsUrl } from '../services/utils'

export class UserApi {
    static async getProfile() {
        let resArr = await Promise.all([
            ApiService.fetch('users/me'),
            UserApi.getProfilePicture(),
        ])
        let res = resArr[0]
        let imageRes = resArr[1]
        if (res.ok) {
            let user: UserProfileModel = Transformer.initializeFromData(
                new UserProfileModel(),
                res.data
            )
            user.declinations = res.data.declinations.declinations
            user.profilePicture = imageRes
            user.state = res.data.administrative_division

            user.userInsuredPersons.map((operator) =>
                Object.assign(operator, {
                    has_recent_logged_flight_time: convertToYesOrNo(
                        operator.has_recent_logged_flight_time
                    ),
                    has_medical_waivers: convertToYesOrNo(
                        operator.has_medical_waivers
                    ),
                    has_recent_accidents: convertToYesOrNo(
                        operator.has_recent_accidents
                    ),
                    has_recent_violations: convertToYesOrNo(
                        operator.has_recent_violations
                    ),
                    pilot_training: operator.pilot_training
                        .split(',')
                        .map((x) => x.trim())
                        .filter((k) => k != 'None'),
                })
            )
            res.parsedData = user

            //TODO: a better solution to Set user id for Google Tag Manager Service
            DataLayerService.userId = user.userId
        }
        return res
    }

    static async getStates() {
        let response = await ApiService.fetch(
            'general/administrative_divisions'
        )
        if (response.ok) {
            let states = []
            for (let value in response.data.administrative_divisions) {
                let state = new State()
                state.code = value
                state.name = response.data.administrative_divisions[value]
                states.push(state)
            }
            states.sort(function (a, b) {
                return a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            })
            response.parsedData = states
        }
        return response
    }

    static async saveProfilePicture(profileImage) {
        let newProfileImage = await Resizer.imageFileResizer(
            profileImage,
            250,
            250,
            'JPEG',
            100,
            0,
            'base64'
        )
        let resizedImage = dataImageToFile(newProfileImage, profileImage.name)
        return ApiService.uploadFile(
            'users/me/profile_image/upload',
            resizedImage
        )
    }

    static async savePilotDetails(details, pilotTraining) {
        Object.keys(details).map((key) => {
            if (details[key] == 'no') details[key] = false
            if (details[key] == 'yes') details[key] = true
        })
        details.pilot_training = pilotTraining
        details.pilot_training =
            details.pilot_training.length > 0
                ? details.pilot_training.join(', ')
                : 'None'
        return await ApiService.fetch(
            'users/me/persons',
            HTTPMethod.POST,
            details
        )
    }


    static async getPilotSchools(filter) {
        let urlEncoded = buildQueryParamsUrl("users/me/persons/getFlightSchool", {
            filter
          });
          return ApiService.fetch(urlEncoded, HTTPMethod.GET);
    }

    static async getPlanDurationPerState() {
        return ApiService.fetch('general/planDurationPerState', HTTPMethod.GET)
    }

    static async saveParent(profile) {
        let res = await ApiService.fetch(
            'users/me/saveParent',
            HTTPMethod.POST,
            {
                first_name: profile.fname,
                last_name: profile.lname,
                address1: profile.address_line_1,
                city: profile.city,
                administrative_division: profile.state,
                zip_code: profile.zipcode,
                date_of_birth: dayjs(profile.dob).format('YYYY-MM-DD'),
                phone_number: profile.phone_number,
                email: profile.email,
            }
        )
    }

    static async checkDeclinations() {
        return await ApiService.fetch(
            'users/me/persons/checkDeclinations', HTTPMethod.GET)
    }

    static async saveProfile(profile) {
        let res = await ApiService.fetch('users/me', HTTPMethod.POST, {
            first_name: profile.fname,
            last_name: profile.lname,
            company: profile.company_name,
            address1: profile.address_line_1,
            address2: profile.address_line_2,
            city: profile.city,
            administrative_division: profile.state,
            zip_code: profile.zipcode,
            date_of_birth: dayjs(profile.dob).format('YYYY-MM-DD'),
            phone_number: profile.phone_number,
            single_engine_seaplane: profile.single_engine_seaplane,
            multi_engine_land: profile.multi_engine_land,
            insurance_type: profile.insurance_type,
            parent: profile.parent_info
                ? {
                      first_name: profile.parent_info?.fname,
                      last_name: profile.parent_info?.lname,
                      address1: profile.parent_info?.address_line_1,
                      city: profile.parent_info?.city,
                      administrative_division: profile.parent_info?.state,
                      zip_code: profile.parent_info?.zipcode,
                      date_of_birth: dayjs(profile.parent_info?.dob).format(
                          'YYYY-MM-DD'
                      ),
                      phone_number: profile.parent_info?.phone_number,
                      email: profile.parent_info?.email,
                  }
                : null,
        })
        if (res.ok) return UserApi.getProfile()
        else return res
    }

    static async getProfilePicture() {
        let res = await ApiService.fetch('users/me/profile_image')
        if (res.ok) return res.data.image_url
        else return ''
    }

    static async updateCreditCard(token) {
        return await ApiService.fetch(
            'creditcards/me',
            HTTPMethod.POST,
            token.id
        )
    }

    static async getCreditCardInfo() {
        let res = await ApiService.fetch('creditcards/me')
        if (res.ok) {
            let creditCardModel = new CreditCardModel()
            creditCardModel.lastFourDigits = res.data.last_four_digits
            creditCardModel.expMonth = res.data.expiration_month.toString()
            creditCardModel.expYear = res.data.expiration_year.toString()
            creditCardModel.brand = res.data.brand
                ? res.data.brand.toLowerCase()
                : 'visa'
            res.parsedData = creditCardModel
        }
        return res
    }

    static async patchUserState(state) {
        return await ApiService.fetch('users/me', HTTPMethod.PATCH, [
            {
                value: state,
                path: '/administrative_division',
                op: 'replace',
            },
        ])
    }

    static async setIsCommercial() {
        return await ApiService.fetch('users/me', HTTPMethod.PATCH, [
            {
                value: true,
                path: '/commercial',
                op: 'replace',
            },
        ])
    }

    static async getAdditionalInsureds() {
        let res = await ApiService.fetch('users/me/additionalInsured')
        if (res.ok && res.data) {
            res.parsedData = removeDuplicateAdditionalInsured(
                res.data.sort((a, b) => b.id - a.id)
            )
        }
        return res
    }

    static async hasFailedActivationInLast5Days(){
        let res= await ApiService.fetch(`insurances/HasFailedActivationInLast5Days`, HTTPMethod.GET);
        return res
      }

      static async purchaseAfterCcFailure(){
        let res= await ApiService.fetch(`insurances/PurchaseAfterCcFailure`, HTTPMethod.POST);
        return res
      }

    static async attachPaymentMethod(
        paymentMethodToken,
        isAttachedAlready,
        method
    ) {
        return await ApiService.fetch(
            'creditcards/me/attach-payment-method',
            HTTPMethod.POST,
            {
                payment_method_token: paymentMethodToken,
                is_attached_already: isAttachedAlready,
                method,
            },
            true
        )
    }

    static async manageDeclinations(body){
        const res = await ApiService.fetch("users/me/persons/manage-declinations", HTTPMethod.POST,body);
        return res; 
      }
     
}
