import React, { useReducer } from 'react'
import { Action } from '../models/Action'

export const ACTIONS = {
    PROFILE_SAVE: 'PROFILE_SAVE',
    SAVE_ADDRESS: 'SAVE_ADDRESS',
    SAVE_USER_INFO: 'SAVE_USER_INFO',
    SAVE_INSURANCE_HISTORY: 'SAVE_INSURANCE_HISTORY',
    SAVE_INSURANCE_TYPE: 'SAVE_INSURANCE_TYPE',
    SAVE_USER_PARENT: 'SAVE_USER_PARENT',
}

const defaultState = {
    dispatch: (action: Action) => {},
    address: {},
    user_info: {},
    insurance_history: {},
    insurance_type: {},
    address_saved: false,
    user_info_saved: false,
    insurance_history_saved: false,
    insurance_type_saved: false,
    user_parent_saved: false,
}

const reducer = (state, action: Action) => {
    let newState = state
    switch (action.type) {
        case ACTIONS.SAVE_ADDRESS:
            newState = { ...state, address: action.data, address_saved: true }
            break
        case ACTIONS.SAVE_USER_INFO:
            newState = {
                ...state,
                user_info: action.data,
                user_info_saved: true,
            }
            break
        case ACTIONS.SAVE_USER_PARENT:
            newState = {
                ...state,
                parent_info: action.data,
                user_parent_saved: true,
            }
            break
        case ACTIONS.SAVE_INSURANCE_HISTORY:
            newState = {
                ...state,
                insurance_history: action.data,
                insurance_history_saved: true,
            }
            break
        case ACTIONS.SAVE_INSURANCE_TYPE:
            newState = {
                ...state,
                insurance_type: action.data,
                insurance_type_saved: true,
            }
            break
        case 'RESET':
            newState = {
                ...state,
                insurance_type_saved: false,
                insurance_history_saved: false,
                user_info_saved: false,
                address_saved: false,
            }
            break
        default:
            newState = state
            break
    }

    return newState
}

export const UserProfileContextStore = React.createContext(defaultState)

const UserProfileContext = (props) => {
    const [state, dispatch] = useReducer(reducer, defaultState)

    return (
        <UserProfileContextStore.Provider value={{ ...state, dispatch }}>
            {props.children}
        </UserProfileContextStore.Provider>
    )
}

export default UserProfileContext
