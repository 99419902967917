import React, { Suspense, lazy } from 'react'
import './SkyCookiesConsent.scss'
const CookieConsent = lazy(() => import('react-cookie-consent'))

export const SkyCookiesConsent = () => {
    return (
        <Suspense fallback={<div />}>
            <CookieConsent
                cookieName="newskywatchcookieconset"
                containerClasses="cookiecontainer"
                buttonText="Got it!"
                buttonClasses="cookiebtn"
                location="bottom"
            >
                <div className="text-small">
                    We use cookies to enhance your experience. By continuing to
                    visit this site you agree to our use of cookies.{' '}
                    <a
                        className="white"
                        href="https://www.skywatch.ai/terms-conditions"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        More info
                    </a>
                </div>
            </CookieConsent>
        </Suspense>
    )
}
