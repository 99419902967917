import { Drone } from '../hull-models/Drone'
import { Equipment } from '../hull-models/Equipment'

export class PhysicalDamageExtension {
    insuredDroneList: Drone[]
    insuredEquipmentList: Equipment[]
    validFromUtc: Date
    validUntilUtc: Date
    endorsementsUrl: String
}
