import React from 'react'
import {
    Switch,
    Route,
    useRouteMatch,
    useHistory,
    useLocation,
    Redirect,
} from 'react-router-dom'
import {
    EventEmitterService,
    EventKey,
} from '../../services/EventEmitterService'
import { TakePictureFlow } from '../Camera/TakePictureFlow'
import { OdometerFlowCongratz } from '../Insurance/OdometerFlowCongratz'
import { OdometerPermissionError } from './OdometerPermissionError'

export const OdometerFlowRoutes = () => {
    const { path, url } = useRouteMatch()
    const history = useHistory()
    const location = useLocation()

    // React.useEffect(() => {

    //     EventEmitterService.subscribe(EventKey.CAMERA_PERMISSION_DENIED, () => {
    //         // history.replace('cameraPermissionError')
    //     })
    // }, [])

    return (
        <Switch>
            <Route path={`${path}/picture`}>
                <TakePictureFlow />
            </Route>

            <Route path={`${path}/congrats`}>
                <OdometerFlowCongratz />
            </Route>
            {/* 
            <Route path={`${path}/cameraPermissionError`}>
                <OdometerPermissionError />
            </Route> */}

            <Route>
                <Redirect
                    to={{
                        pathname: `${path}/picture`,
                        search: window.location.search,
                    }}
                />
            </Route>
        </Switch>
    )
}
