import BrokerConfigService from './BrokerConfigService'
import StorageService, { StorageKey } from './StorageService'
import { parseJwt } from './utils'

export class SessionService {
    static setUserToken(newEmail, newToken, expiration) {
        StorageService.setItem(StorageKey.EMAIL, newEmail, expiration)
        StorageService.setItem(StorageKey.TOKEN, newToken, expiration)
        StorageService.setItem(
            StorageKey.TOKEN_EXPIRATION,
            expiration,
            expiration
        )
    }

    static setBrokerOnBehalfToken(token) {
        StorageService.setItem(StorageKey.BROKER_USER_ON_BEHALF_TOKEN, token)
    }

    static resetBrokerOnBehalfToken() {
        StorageService.removeItem(StorageKey.BROKER_USER_ON_BEHALF_TOKEN)
    }

    static getStoredBrokerOnBehalfToken() {
        let token = StorageService.getItem(
            StorageKey.BROKER_USER_ON_BEHALF_TOKEN
        )
        if (token == null) return undefined
        else return token
    }

    static getToken() {
        let brokerOnBehalfToken = this.getStoredBrokerOnBehalfToken()
        if (this.isBroker() && !!brokerOnBehalfToken) return brokerOnBehalfToken
        else return StorageService.getItem(StorageKey.TOKEN)
    }

    static getStoredToken() {
        return StorageService.getItem(StorageKey.TOKEN)
    }

    static getAffiliateCode() {
        if (this.isBroker()) {
            return parseJwt(this.getStoredToken())['PartnershipCode'] || 'web'
        }
        return 'web'
    }

    static isBroker() {
        if (
            !this.getStoredToken() ||
            (this.getStoredToken() &&
                !parseJwt(this.getStoredToken())[
                    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
                ])
        ) {
            return false
        }
        return parseJwt(this.getStoredToken())[
            'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
        ].includes('Partner')
    }

    static isAgencyRequiresIdentityCode() {
        if (BrokerConfigService.getCurrenctThemedSite() == 'firstconnect') {
            return true
        }

        if (
            !this.getStoredToken() ||
            (this.getStoredToken() &&
                !parseJwt(this.getStoredToken())[
                    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
                ])
        ) {
            return false
        }

        return parseJwt(this.getStoredToken())['RequireActorId'] == 'true'
    }

    static isAdmin() {
        if (
            !this.getStoredToken() ||
            (this.getStoredToken() &&
                !parseJwt(this.getStoredToken())[
                    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
                ])
        ) {
            return false
        }
        return parseJwt(this.getStoredToken())['PartnershipCode'] == 'voom'
    }

    static isLoggedIn() {
        return (
            !!this.getToken() ||
            (window.location.search.includes('token') &&
                window.location.pathname != '/changeEmail')
        )
    }

    static deleteSession() {
        StorageService.removeItem(StorageKey.TOKEN)
        StorageService.removeItem(StorageKey.EMAIL)
        StorageService.removeItem(StorageKey.GET_A_QUOTE_HULL_KEY)
        StorageService.removeItem(StorageKey.BROKER_USER_ON_BEHALF_TOKEN)
    }
}
