import React, { useEffect } from 'react'
import { Snackbar, Button } from '@material-ui/core'
import * as serviceWorker from '../serviceWorker'

export const ServiceWorkerWrapper = () => {
    const [showReload, setShowReload] = React.useState(false)
    const [waitingWorker, setWaitingWorker] = React.useState(null)

    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        setShowReload(true)
        setWaitingWorker(registration.waiting)
    }

    useEffect(() => {
        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker.unregister({ onUpdate: onSWUpdate })
    }, [])

    const reloadPage = () => {
        if (waitingWorker) {
            waitingWorker.postMessage({ type: 'SKIP_WAITING' })
            setShowReload(false)
            window.location.reload(true)
        }
    }

    return (
        <Snackbar
            open={showReload}
            message="A new version is available!"
            onClick={reloadPage}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            action={
                <Button color="inherit" size="small" onClick={reloadPage}>
                    Reload
                </Button>
            }
        />
    )
}
