/**
 * This script was created by the marketing team to follow cookies that arrive from other brand websites.
 * We also use the getCookie function to pull out cookie data during app events and post to the backend
 */

const full_url = window.location.href

const setCookie = (name, value, days) => {
  let expires = ''
  const domain = '.skywatch.ai'

  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toUTCString()}`
  }
  document.cookie = `${name}=${value}${expires}; path=/; domain=${domain}`
}
const clearCookie = name => {
  const domain = '.skywatch.ai'
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`
}

export const getCookie = name => {
  const nameEQ = `${name}=`
  const cookiesArray = document.cookie.split(';')
  for (let i = 0; i < cookiesArray.length; i++) {
    const cookie = cookiesArray[i].trim()
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length)
    }
  }
  return null
}

document.addEventListener('DOMContentLoaded', () => {
  const url_cookie = getCookie('page_full_url') || null
  console.log('marketing cookie', url_cookie)

  if (full_url.toLowerCase().includes('?utm_')) {
    setCookie('page_full_url', full_url, 30)
  } else if (url_cookie && url_cookie.toLowerCase().includes('?utm_')) {
    return
  } else {
    setCookie('page_full_url', full_url, 30)
  }
})
