import QuoteModel, { QuoteStateModel } from '../insurance-models/QuoteModel'

export default class UserProfileModel {
    firstName = ''
    lastName = ''
    operator = ''
    address1 = ''
    address2 = ''
    userId: ?Number = -1
    avgFlightScore: ?Number = undefined
    totalCoverageHours: ?Number = 0
    totalScoredFlights: ?Number = 0
    totalDiscount: ?Number = 1
    userRank = { title: 'Silver' }
    discountEligibility: Boolean
    email: String
    city = ''
    insuranceLast60Days: boolean = false
    state = ''
    zipCode = ''
    parent: ParentProfileModel = null
    dateOfBirth: Date = null
    creditBalance: Number = 0
    commercial = false
    hadInsurance: ?boolean = false
    company = ''
    hadInsuranceDescription: ?string = ''
    hasCreditCard = false
    profilePicture = ''
    phoneNumber = ''
    userVehicles = []
    declinations = []
    userInsuredPersons = []
    flightPurpose = 'default'
    latestQuote: ?QuoteModel = undefined
    latestState: ?QuoteStateModel = undefined
    status: ?any = null
    claimCount: Number = 0
    insuranceType = ''

    hasData() {
        return !!this.address1
    }

    static fullNameSplitter(fullName) {
        if (!fullName) {
            return ['', '']
        }
        let nameParts = fullName.split(' ')
        if (nameParts.length == 0) {
            return ['', '']
        }
        if (nameParts.length == 1) {
            return [nameParts[0], '']
        }
        return [nameParts[0], nameParts.slice(1, nameParts.length).join(' ')]
    }

    static fullNameCreator(firstName, lastName) {
        let trimedFirstName = firstName.trim()
        let trimedLastName = lastName.trim()
        if (trimedFirstName == '' && trimedLastName == '') {
            return ''
        }
        if (trimedFirstName == '') {
            return trimedLastName
        }
        if (trimedLastName == '') {
            return trimedFirstName
        }
        // trimedFirstName = trimedFirstName.chatAt(0).toUpperCase() + trimedFirstName.slice(1)
        return trimedFirstName + ' ' + trimedLastName
    }
}

export class ParentProfileModel {
    firstName = ''
    lastName = ''
    address1 = ''
    email: String = ''
    city = ''
    state = ''
    zipCode = ''
    dateOfBirth: Date = null
    phoneNumber = ''
}
