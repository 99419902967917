import { CountryBasedFirebaseInitialize } from '../../models/services/CountryBasedFirebaseInitialize'
import * as firebase from 'firebase/app'
import 'firebase/remote-config'
import 'firebase/database'
import { FirebaseConfigKey } from '../../services/FirebaseService'
import { THREE_WEEKS_IN_MS } from '../../Constants'
import { TemperatureScaleSign } from '../../models/general/TemperatureScaleSign'
import { LengthMeasurement } from '../../models/general/LengthMeasurement'

export class UKFirebaseServiceInitializer extends CountryBasedFirebaseInitialize {
    constructor() {
        super()

        // Initialize Firebase
        let app = firebase.initializeApp({
            apiKey: 'AIzaSyBfXImj0FHVslo3zyxCht6BWwvVvzJb3Zc',
            authDomain: 'skywatch-4281c.firebaseapp.com',
            databaseURL: 'https://skywatch-4281c.firebaseio.com',
            projectId: 'skywatch-4281c',
            storageBucket: 'skywatch-4281c.appspot.com',
            messagingSenderId: '734682503233',
            appId: '1:734682503233:web:bfe5d254a84bdf88ca0ec0',
            measurementId: 'G-5NF18TJ8L8',
        })
        this.app = app
        this.remoteConfig = firebase.remoteConfig(app)

        firebase.analytics(app).setUserProperties({ selectedCountry: 'UK' })

        // send an event, so user properties are uploaded.
        firebase
            .analytics(app)
            .logEvent('aviation-web-portal-loading', { test: true })

        this.remoteConfig.defaultConfig = {
            [FirebaseConfigKey.COMMERCIAL_VIEW_STRINGS_JSON]:
                '{"terms_and_agreements_suffix":"I hereby confirm that I have the required FAA certificates for performing commercial UAV operations"}',
            [FirebaseConfigKey.GUIDELINES_STRINGS_JSON]: undefined, //'{"permanentStrings":["Fly according to CAA rules and certification requirements","Noise Liability - £2,500 – any one occurrence and in the annual aggregate, deductible - £250 each occurrence and in the annual aggregate","Invasion of Privacy - limited to £2,500  - any one occurance and in the annual aggregate, deductible - £250 each occurance and in the annual aggregate","Legal Liability to Third Parties - Bodily Injury and/or Property - Damage £1M each occurrence, deductible - £250 each occurrence","3rd Party War Liability - Bodily injury and property damage £1M combined, each occurrence and in the annual aggregate. Deductible - £250 each occurrence and in the annual aggregate"],"monthlyOnlyStrings":["Your coverage starts now and ends in 1 month","Your subscription is set to automatically renew at the end of the monthly billing period","You can cancel the next upcoming payment at any time","Flight planning using the app is not mandatory, you’re covered everywhere within the UK","You can use the Skywatch app to access safety features or get on-demand insurance for higher liability limits"],"annualOnlyStrings":[]}',
            [FirebaseConfigKey.GUIDELINE_KEY_FACTS_URL_HOURLY]: '',
            [FirebaseConfigKey.GUIDELINE_KEY_FACTS_URL_MONTHLY]: '',
            [FirebaseConfigKey.HULL_INSURANCE_AVAILABLE]: true,
            [FirebaseConfigKey.HULL_ESTIMATED_PREMIUM_FACTOR]: 0.096 / 12,
            [FirebaseConfigKey.HULL_ESTIMATED_DEDUCTIBLE_FACTOR]: 0.1,
            [FirebaseConfigKey.HULL_ESTIMATED_PREMIUM_FACTOR_ANNUAL]: 0.096,
            [FirebaseConfigKey.HULL_ESTIMATED_DEDUCTIBLE_FACTOR_ANNUAL]: 0.1,
            [FirebaseConfigKey.HULL_LIABILITY_MINIMUM]: 500000,
            [FirebaseConfigKey.TEMPERATURE_SCALE_SIGN]:
                TemperatureScaleSign.FARENHEIT,
            [FirebaseConfigKey.DIFF_BETWEEN_RATE_APP_REQUEST_MILLISECONDS]:
                THREE_WEEKS_IN_MS,
            [FirebaseConfigKey.APP_RATING_BLOCKED]: false,
            [FirebaseConfigKey.USER_MUST_BE_COMMERCIAL]: false,
            [FirebaseConfigKey.GDPR_REGISTRATION_CONFIRMATION_TEXT]: '',
            [FirebaseConfigKey.COUNTRY]: 'UK',
            [FirebaseConfigKey.GDPR_REQUIRED]: false,
            [FirebaseConfigKey.COUNTRY_AWARENESS_BLOCKED]: true,
            [FirebaseConfigKey.ADDITIONAL_INSURED_NOTICE]: '',
            [FirebaseConfigKey.FAQ_URL]: '',
            [FirebaseConfigKey.REFERRAL_PROGRAM_ENABLED]: false,
            [FirebaseConfigKey.IS_INDOOR_COVERAGE_AVAILABLE]: false,
            [FirebaseConfigKey.REFERRAL_PROGRAM_CREDIT_LIMIT]: 30.38,
            [FirebaseConfigKey.PERFORMANCE_MONITORING_ENABLED]: true,
            [FirebaseConfigKey.USER_RECORDING_ENABLED]: false,
            [FirebaseConfigKey.LIABILITY_LIMITS]:
                '[500000, 1000000, 2000000, 5000000, 10000000]',
            [FirebaseConfigKey.SMARTLOOK_KEY]:
                '58ca0720a0459eb212618f195f04ea807576b9f0',
            [FirebaseConfigKey.CURRENCY_SYMBOL]: '£',
            [FirebaseConfigKey.INSURANCE_COMPANY_NAME]: 'tmk',
            [FirebaseConfigKey.POLICY_EXTENSION_AVAILABLE]: true,
            [FirebaseConfigKey.FILE_A_CLAIM_URL]:
                'https://portal.skywatch.ai/claim',
            [FirebaseConfigKey.CONTACT_US_URL]:
                'https://faq.skywatch.ai/hc/en-gb/requests/new',
            [FirebaseConfigKey.AVAILABLE_COUNTRIES]: ['US', 'UK'],
            [FirebaseConfigKey.HULL_INSURANCE_AVAILABLE_FOR_MONTHLY]: true,
            [FirebaseConfigKey.HULL_INSURANCE_AVAILABLE_FOR_ANNUAL]: true,
            [FirebaseConfigKey.SHORT_DATE_FORMAT]: 'dd/mm',
            [FirebaseConfigKey.LONG_DATE_FORMAT]: 'DD/MM/YYYY',
            [FirebaseConfigKey.DATE_TIME_FORMAT]: 'MMM D YYYY, h:mm A',
            [FirebaseConfigKey.MINIMUN_DRONE_VALUE]: '0',
            [FirebaseConfigKey.MAXIMUM_DRONE_VALUE]: '125000',
            [FirebaseConfigKey.MAXIMUM_DRONE_VALUE_FOR_BROKER]: '125000',
            [FirebaseConfigKey.MINIMUN_EQUIPMENT_VALUE]: '0',
            [FirebaseConfigKey.MAXIMUM_EQUIPMENT_VALUE]: '125000',
            [FirebaseConfigKey.MAXIMUM_EQUIPMENT_VALUE_FOR_BROKER]: '125000',
            [FirebaseConfigKey.FTUE]:
                '[{"title":"Drone insurance at your fingertips","subtitle":"","illustration":{"uri":"https://skywatchapp-prod.s3.amazonaws.com/common_files/ftue/Page_01_USA.jpg"}},{"title":"Aviation Liability","subtitle":"By the hour, month or year","illustration":{"uri":"https://skywatchapp-prod.s3.amazonaws.com/common_files/ftue/Page_02_USA.jpg"}},{"title":"Hourly drone insurance","subtitle":"Pay as you fly","illustration":{"uri":"https://skywatchapp-prod.s3.amazonaws.com/common_files/ftue/Page_03_USA.jpg"}},{"title":"Protect Your Drone","subtitle":"Hull coverage (optional)","illustration":{"uri":"https://skywatchapp-prod.s3.amazonaws.com/common_files/ftue/Page_04_USA.jpg"}},{"title":"Instant certificate","subtitle":"Email to you in seconds","illustration":{"uri":"https://skywatchapp-prod.s3.amazonaws.com/common_files/ftue/Page_05_USA.jpg"}}]',
            [FirebaseConfigKey.ADMINISTRATIVE_DIVISION_UNAVAILABLE_TEXT]:
                "Don't worry we're making efforts to be live everywhere. \n\nDrop us a message to support@skywatch.ai and we'll notify you when SkyWatch is available in your state.",
            [FirebaseConfigKey.DEFAULT_LIABILITY]: 1000000,
            [FirebaseConfigKey.INSURANCE_OPTIONS_CONFIGURATION]:
                '{"monthly": {"whatsIncluded": ["Fly anytime, anywhere in the US", "Hull coverage available"], "fromPrice": 42}, "annual": {"whatsIncluded": ["Fly anywhere all year long", "Hull coverage available"], "fromPrice": 500}, "hourly": {"whatsIncluded": ["Pay by the hour", "Pre-booking options available"], "fromPrice": 10}}',
            [FirebaseConfigKey.UNIT_LENGTH]: LengthMeasurement.MILES,
            [FirebaseConfigKey.MIN_SAVINGS_DISPLAY]: 5,
            [FirebaseConfigKey.ZIPCODE_REGEX]:
                '^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$',
            [FirebaseConfigKey.LIABILITY_COVERAGE_TEXT]:
                '{"general": ["Personal injuries and invasion of privacy claims","Bodily injury","Medical expenses", "Property damage"],"onlyWithHull": []}',
            [FirebaseConfigKey.RESTRICTED_COUNTRIES]: '',
            [FirebaseConfigKey.ANNUAL_AVAILABLE]: true,
            [FirebaseConfigKey.MONTHLY_AVAILABLE]: false,
            [FirebaseConfigKey.LIABILITY_DEDUCTIBLE]:
                '{"value":250,"factor":"currency"}',
            [FirebaseConfigKey.REGISTRATION_AVAILABLE]: false,
            [FirebaseConfigKey.INSURED_LOGIN_AVAILABLE]: false,
            [FirebaseConfigKey.PAYMENT_REQUIRED_FOR_BROKER]: false,
            [FirebaseConfigKey.DRONE_IMAGE_REQUIRED_FOR_BROKER]: false,
            [FirebaseConfigKey.CLAIM_QUESTION]:
                'Have you had any drone claims or incidents in the last 12 months?',
            [FirebaseConfigKey.SHOULD_DISPLAY_HULL_ESTIMATIONS]: false,
            [FirebaseConfigKey.TOTAL_HULL_SUM_INSURED]: 250000,
            [FirebaseConfigKey.LIABILTY_LIMIT_EXTENSION_AVAILABLE]: false,
            [FirebaseConfigKey.PAUSE_POLICY_AVAILABLE]: false,
            [FirebaseConfigKey.DRONE_TAKEOFF_WEIGHTS]:
                '["<0.25Kg","0.25Kg - 4.9Kg","5Kg - 9.9Kg","10Kg - 14.9Kg","15Kg - 19.9Kg","20Kg - 24.9Kg","25Kg - 29.9Kg","30Kg+"]',
            [FirebaseConfigKey.IS_HULL_EDITABLE]: true,
            [FirebaseConfigKey.WHATS_COVERED_AVAILABLE]: false,
            [FirebaseConfigKey.OPERATOR_EDITING_AVAILABLE]: false,
            [FirebaseConfigKey.HULL_HEADER_TEXT]: 'Drones & Equipment',
            [FirebaseConfigKey.SHOW_HULL_PREMIUMS_ON_POLICY_PAGE]: false,
            [FirebaseConfigKey.FLIGHT_LOGS_URL]:
                'https://user.skywatch.ai/insurance-discounts',
            [FirebaseConfigKey.PROPOSAL_LINK_AVAILABLE]: true,
            [FirebaseConfigKey.MEDICAL_EXPENSES]: '[500, 1000, 2000, 5000]',
            [FirebaseConfigKey.PERSONAL_INJURY_COVERAGE]: '[25000, 100000]',
            [FirebaseConfigKey.SUPPORT_PHONE_NUMBER]: '650-332-9128',
            [FirebaseConfigKey.PRETTY_COUNTRY_NAME]: 'the United Kingdoms',
            [FirebaseConfigKey.WORLD_WIDE_TEXT]:
                'Skywatch can provide a worldwide coverage outside of the UK for commercial purposes. For more info',
            [FirebaseConfigKey.ADDITIONAL_COVERAGE_TOOLTIP]:
                '["Add coverage for flying indoors", "SkyWatch.AI policies provide £25,000 in personal injury and £500 in medical expenses.","Upgrade your coverage for an additional premium.","SkyWatch.AI can provide worldwide coverage territory for commercial purposes upon request.","Contact us to learn more."]',
            [FirebaseConfigKey.MINIMUM_HULL_VALUE_FOR_IMAGE_MANDATORY]: 1999,
        }
    }
}
