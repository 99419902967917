import React, { useReducer } from 'react'
import { Action } from '../models/Action'
import PolicyManagementApi from '../api/policymanagement.api'
import { INSURANCE_CONTEXT_ACTIONS } from './InsuracePurchaseContext'
import { HullApi } from '../api/hull.api'
import { EventEmitterService, EventKey } from '../services/EventEmitterService'
import { UserApi } from '../api/user.api'
import { removeDuplicateAdditionalInsured } from '../services/utils'
import policymanagementApi from '../api/policymanagement.api'
import { SessionService } from '../services/SessionService'

export const ACTIONS = {
    LOAD_POLICIES: 'LOAD_POLICIES',
    LOADING_POLICIES: 'LOADING_POLICIES',
    TOGGLE_AUTO_RENEW: 'SET_AUTO_RENEW',
    EQUIPMENT_TYPES_LOADED: 'EQUIPMENT_TYPES_LOADED',
    UPDATE_INSURANCE: 'UPDATE_INSURANCE',
    SCHEDULED_INSURANCE_CANCELED: 'SCHEDULED_INSURANCE_CANCELED',
    TOTAL_ADDITIONAL_INSUREDS_UPDATED: 'TOTAL_ADDITIONAL_INSUREDS_UPDATED',
}

const defaultState = {
    dispatch: (action: Action) => {},
    getInsurance: (insuranceId) => {},
    pausePolicy: (insuranceId, endDate) => {},
    unpausePolicy: (insuranceId) => {},
    all_policies: [],
    active_policies: [],
    scheduled_policies: [],
    previous_policies: [],
    allAdditionalInsuredList: [],
    loading_policies: false,
}

const reducer = (state, action: Action) => {
    console.log('PolicyManagementContext state', state, action)
    switch (action.type) {
        case ACTIONS.LOAD_POLICIES:
            return {
                ...state,
                all_policies: [
                    ...action.data.active_policies,
                    ...action.data.scheduled_policies,
                    ...action.data.previous_policies,
                ],
                active_policies: action.data.active_policies,
                scheduled_policies: action.data.scheduled_policies,
                previous_policies: action.data.previous_policies,
                loading_policies: false,
            }
        case ACTIONS.LOADING_POLICIES:
            return { ...state, loading_policies: true }
        case ACTIONS.SCHEDULED_INSURANCE_CANCELED:
            return {
                ...state,
                scheduled_policies: state.scheduled_policies.filter(
                    (q) => q.insuranceId != action.data.insuranceId
                ),
            }
        case ACTIONS.TOGGLE_AUTO_RENEW:
            return {
                ...state,
                active_policies: state.active_policies.map((i) =>
                    i.insuranceId == action.data.insuranceId
                        ? Object.assign(i, { autoRenew: !i.autoRenew })
                        : i
                ),
                scheduled_policies: state.scheduled_policies.map((i) =>
                    i.insuranceId == action.data.insuranceId
                        ? Object.assign(i, { autoRenew: !i.autoRenew })
                        : i
                ),
            }
        case ACTIONS.TOTAL_ADDITIONAL_INSUREDS_UPDATED:
            return {
                ...state,
                allAdditionalInsuredList: action.data.additional_insureds,
            }
        case ACTIONS.UPDATE_INSURANCE:
        case INSURANCE_CONTEXT_ACTIONS.EDIT_ADDITIONAL_INSURED:
        case INSURANCE_CONTEXT_ACTIONS.DELETE_ADDITIONAL_INSURED:
        case INSURANCE_CONTEXT_ACTIONS.ADD_ADDITIONAL_INSURED:
        case INSURANCE_CONTEXT_ACTIONS.ACTIVE_ADDITIONAL_INSUREDS_UPDATED:
            if (!action.data.insurance) return state
            return {
                ...state,
                active_policies: state.active_policies.map((i) =>
                    i.insuranceId == action.data.insurance.insuranceId
                        ? Object.assign(i, action.data.insurance)
                        : i
                ),
                scheduled_policies: state.scheduled_policies.map((i) =>
                    i.insuranceId == action.data.insurance.insuranceId
                        ? Object.assign(i, action.data.insurance)
                        : i
                ),
            }

        default:
            return state
    }
}

export const PolicyManagementContextStore = React.createContext(defaultState)

const PolicyManagementContext = (props) => {
    const [state, dispatch] = useReducer(reducer, defaultState)

    const loadPolicies = async () => {
        dispatch(new Action(ACTIONS.LOADING_POLICIES))
        let res = await PolicyManagementApi.getAllInsurances()
        if (res.ok) {
            let dt = new Date()
            dispatch(
                new Action(ACTIONS.LOAD_POLICIES, {
                    active_policies: res.parsedData.filter(
                        (item) =>
                            item.policyStatus === "Active"
                    ),
                    scheduled_policies: res.parsedData.filter(
                        (item) => item.policyStatus === 'Scheduled'
                    ),
                    previous_policies: res.parsedData.filter(
                        (item) => item.policyStatus === 'Expired' || item.policyStatus === 'Canceled'
                    ),
                })
            )
        }
    }

    const loadAdditionalInsureds = async () => {
        let res = await UserApi.getAdditionalInsureds()
        if (res.ok) {
            dispatch(
                new Action(ACTIONS.TOTAL_ADDITIONAL_INSUREDS_UPDATED, {
                    additional_insureds: res.parsedData,
                })
            )
        }
    }

    const getInsurance = (insuranceId) => {
        return state.all_policies.find(
            (insurance) => insurance.insuranceId == insuranceId
        )
    }

    const pausePolicy = async (insuranceId, endDate) => {
        let res = await policymanagementApi.suspendPolicy(insuranceId, endDate)

        if (res.ok && SessionService.isAdmin()) loadPolicies()

        return res
    }

    const unpausePolicy = async (insuranceId) => {
        let res = await policymanagementApi.unsuspendPolicy(insuranceId)

        if (res.ok && SessionService.isAdmin()) loadPolicies()

        return res
    }

    const cancelPolicy = async (insuranceId, isFullRefund, insuranceType) => {
        let res
        if (isFullRefund) {
            res = await policymanagementApi.FullCancelPolicy(insuranceId, insuranceType)
        } else {
            res = await policymanagementApi.cancelPolicyProRata(insuranceId, insuranceType)
        }

        if (res.ok && SessionService.isAdmin()) loadPolicies()

        return res
    }

    React.useEffect(() => {
        loadPolicies()
        //loadAdditionalInsureds()

        let id = EventEmitterService.subscribe(
            EventKey.SCHEDULED_INSURANCE_CANCELED,
            (insuranceId) => {
                dispatch(
                    new Action(
                        ACTIONS.SCHEDULED_INSURANCE_CANCELED,
                        insuranceId
                    )
                )
            }
        )

        return function cleanup() {
            EventEmitterService.unsubscribe(
                EventKey.SCHEDULED_INSURANCE_CANCELED,
                id
            )
        }
    }, [])

    return (
        <PolicyManagementContextStore.Provider
            value={{
                ...state,
                dispatch,
                getInsurance,
                pausePolicy,
                unpausePolicy,
                cancelPolicy,
            }}
        >
            {props.children}
        </PolicyManagementContextStore.Provider>
    )
}

export default PolicyManagementContext
