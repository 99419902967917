import { THEMES } from '../theme/Theme'
import FirebaseDatabaseService from './FirebaseDatabaseService'
import PaletteGeneratorService from './PaletteGeneratorService'

class BrokerConfigService {
    brokerName: String = ''

    constructor() {
        let domain = document.URL
        domain = domain.replace('https://', '').replace('http://', '')
        let subDomain = domain.split('.')
        if (subDomain.length > 1) subDomain = subDomain[0]
        else subDomain = 'skywatch'

        if (subDomain == 'new') subDomain = 'skywatch'

        if (subDomain == 'portal') subDomain = 'skywatch'

        this.brokerName = subDomain
    }

    async loadRemoteTheme() {
        let config = await FirebaseDatabaseService.getSnapshotData(
            'theme',
            this.brokerName
        )
        if (!config) {
            this.brokerName = 'skywatch'
            config = await FirebaseDatabaseService.getSnapshotData(
                'theme',
                this.brokerName
            )
        } else {
            this.saveThemeToCache(config)
        }

        if (!this.isSkywatchBroker()) {
            if (!Boolean(config.useColoredBackground))
                config.useColoredBackground = false
        }

        return Object.assign(
            {},
            PaletteGeneratorService.generatePalette(
                config.blue,
                config.useColoredBackground
            ),
            config
        )
    }

    isSkywatchBroker() {
        return this.brokerName == 'skywatch'
    }

    async getValue(key) {
        let config = await this.loadRemoteTheme()
        if (config && config[key]) return config[key]
        return ''
    }

    getLogo() {
        return `https://skywatchapp-prod.s3.amazonaws.com/broker-logos/logos/${this.brokerName}_logo.png`
    }

    getSkywatchLogo() {
        return `https://skywatchapp-prod.s3.amazonaws.com/broker-logos/logos/skywatch_blue_logo.png`
    }

    getFavicon() {
        return `https://skywatchapp-prod.s3.amazonaws.com/broker-logos/favicons/${this.brokerName}_favicon.ico`
    }

    getDefaultTheme() {
        return THEMES.skywatch.theme
    }

    async getRedeemCode() {
        let config = await this.loadRemoteTheme()
        if (config && config.redeem_code) return config.redeem_code
        return undefined
    }

    getCurrenctThemedSite() {
        return this.brokerName
    }

    getCachedThemeOrDefault() {
        var themeString = localStorage.getItem('theme')
        if (themeString) {
            var theme = JSON.parse(themeString)
            if (this.brokerName in theme) {
                return theme[this.brokerName]
            }
        }
        return this.getDefaultTheme()
    }

    saveThemeToCache(theme) {
        let themeCache = {}
        var themeString = localStorage.getItem('theme')

        if (themeString) {
            Object.assign(themeCache, JSON.parse(themeString))
        }

        themeCache[this.brokerName] = theme

        localStorage.setItem('theme', JSON.stringify(themeCache))
    }
}

export default new BrokerConfigService()
